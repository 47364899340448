export default {
    chiSiamo(state) {
        return state.chiSiamo;
    },
    attivita(state) {
        return state.attivita;
    },
    vocazione(state) {
        return state.vocazione;
    },
    approfondimenti(state) {
        return state.approfondimenti;
    },
    pregaConNoi(state) {
        return state.pregaConNoi;
    },
    contatti(state) {
        return state.contatti;
    },
    navbar(state) {
        return state.navbar;
    },
    footer(state) {
        return state.footer;
    },
}